import React from 'react'

import { graphql } from 'gatsby'
import Layout from '../layouts/layout'
import SEO from '../components/seo'
import Container from '../components/container'
import Hero from '../components/Hero/Hero'
import styled from '@emotion/styled'
import Button from '../components/Button/Button'

const Content = styled.div`
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-shadow: 0px 3px 6px #00000029;
    padding: 2rem;
    position: relative;
    margin-bottom: 4rem;

    @media (min-width: 768px) {
        margin-top: -3.5rem;
    }
`

const Buttons = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    flex-wrap: wrap;
    margin-bottom: 2rem;
`

const NotFoundPage = ({ data }) => (
    <Layout>
        <SEO title="404: Not found" />
        <Hero
            align="center"
            image={data.heroImage}
            title="Page Not Found"
            css={{ paddingTop: 200 }}
        >
            <p>
                We&#39;re sorry but the page you&#39;re trying to visit does not
                exist
            </p>
        </Hero>
        <Container>
            <Content>
                <p>
                    Explore the pages below or use the search to find what
                    you&#39;re looking for.
                </p>
                <Buttons>
                    <Button to="/">Home</Button>
                    <Button to="/products">Products</Button>
                    <Button to="/news">News Room</Button>
                </Buttons>
            </Content>
        </Container>
    </Layout>
)

export default NotFoundPage

export const query = graphql`
    query {
        heroImage: file(relativePath: { eq: "404-bg.png" }) {
            ...heroImage
        }
        siteSearchIndex {
            index
        }
    }
`
